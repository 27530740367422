@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Roboto+Condensed:wght@400;700&display=swap");

.Card {
  font-family: "Roboto", sans-serif;
  justify-content: center;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.card-list > div {
  width: 470px;
}
.Card .arrow {
  width: 20px;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.Card .arrow.rotated {
  transform: rotate(180deg);
  margin-top: -4px;
}
.Card .content-card.hide {
  max-height: 0;
  transition: max-height 0.3s ease;
}
.Card .content-card {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 400px;
}
.Card h2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  margin: 5px 0;
}
.Card .header-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #000;
}
.Card .seats {
  width: 48px;
  height: 26px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  background-color: #dadada;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
}
.Card .seats-small {
  width: 40px;
  height: 24px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  color: #fff;
  background-color: #dadada;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
}
.Card .container-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 9px;
  padding: 6px 0;
}
.Card .status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  line-height: 36px;
}
.Card .icon {
  width: 14px;
  display: inline-flex;
  vertical-align: middle;
  margin-top: -3px;
}
.Card .text {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 36px;
  margin-left: 5px;
}
.Card .icon.complete {
  fill: #46b33c;
}
.Card .text.complete {
  color: #46b33c;
}
.Card .icon.pending {
  fill: #191919;
}
.Card .text.pending {
  color: #191919;
}
.Card .icon.counting {
  fill: #e07000;
}
.Card .text.counting {
  color: #e07000;
}
.Card .count-elected {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  color: #191919;
  text-align: right;
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  line-height: 32px;
  background-color: #f1f1f1;
}
.Card .count-elected > div {
  margin: 0 13px;
  font-family: "Roboto", sans-serif;
}
.Card .count-elected > div:last-of-type {
  margin-right: 10px;
}
.Card .full-data {
  width: 100%;
  border-collapse: collapse;
}
.Card .full-data tr {
  height: 48px;
  border-bottom: 1px solid #d6d6d6;
}
.Card .full-data td {
  font-size: 16px;
  text-align: left;
  vertical-align: middle;
}
.Card td.first-pref {
  font-weight: 700;
  text-align: right;
  width: 80px;
}
.Card td.count-e {
  text-align: right;
  padding-right: 10px;
  width: 20px;
}
.Card .party-box {
  text-align: left;
  padding-left: 0;
  padding-right: 5px;
  width: 50px;
}
