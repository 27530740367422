.CountStory{
    font-family: "Roboto", sans-serif;
    padding: 0px 20px 5px 0px;
    color: #444;
    text-align: center;
    --candidate-name-width: 150px;
    --bar-width: 35px; 
}

.CountStory .controls-group{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.CountStory .control-btn{
    font-size: 14px;
    padding: 5px 10px;
    background: #f5f6f8;
    border: 2px solid #eaeef1;
    border-radius: 4px;
    color: #222;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.CountStory .control-btn:disabled{
    background: #eaeced;
    color: #999;
}

.CountStory .control-icon{
    font-size: 1.25em
}

.CountStory .count-info{
    font-weight: 700;
    font-size: 1.25em;
}

.CountStory .story-container{
    position: relative;
}


.CountStory .story-wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 18px;
}

.CountStory .elected-line{
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-top: 1px dashed rgb(170, 170, 170);
    width: calc(94% + 40px);
    z-index: 2;
    height: 1px;
    opacity: 1;
}

.CountStory .elected-text{
    position: absolute;
    top: -12px;
    right: 15px;
    background-color: rgba(254, 254, 254, 0.6);
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 14px;
}


.CountStory .quota-line{
    position: absolute;
    bottom: 0px;
    border-left: 1px dashed rgb(170, 170, 170);
    width: 1px;
    z-index: 3;
    top: -15px
}

.CountStory .quota-text{
    position: absolute;
    top: 2.5px;
    left: -100px;
    color: #333333;
    background-color: #fefefe99;
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 14px;
    white-space: nowrap;
}

.CountStory .candidate-container{
    display: flex;
    justify-content: start;
    text-align: left;
    gap: 10px
}


.CountStory .candidate-name{
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    text-align: left;
    line-height: var(--bar-width);
    min-width: var(--candidate-name-width);
    max-width: var(--candidate-name-width);

}

.CountStory .votes-bar{
    display: inline-block;
    color: #eeeeee;
    text-shadow: #535353 1px 1px;
    text-align: left;
    padding-left: 5px;
    vertical-align: middle;
    border-radius: 0 2px 2px 0;
    font-weight: 700;
    height: var(--bar-width);
    line-height: var(--bar-width);
}

@media (max-width: 767px) {
    .CountStory { 
        padding: 0px 10px 5px 0px;
        font-size: 14px;
        --candidate-name-width: 100px;
    }
    .CountStory .control-btn{
       flex-direction: column;
       font-size: 13px;
       gap:2px;
    }
    .CountStory .count-info{
       display: inline-flex;
       flex-direction: column;
    }

    .CountStory .controls-group{
        gap: 15px;
    }
    
    
}