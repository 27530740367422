@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Roboto+Condensed:wght@400;700&display=swap');

.CardMetadata{
    font-family: "Roboto", sans-serif;
}
.CardMetadata .metadata{
    border-top: 2px solid #000;
    padding-top: 5px;
    min-width: 280px;
    text-align: left;
}
.CardMetadata h2{
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    margin: 5px 0;
}
.CardMetadata .metadata-table{
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
}
.CardMetadata .metadata-table tr{
    height: 36px;
    border-bottom: 1px solid #D6D6D6;
}
.CardMetadata .metadata-table td{
    font-size: 16px;
    width: 123px;
}
.CardMetadata .metadata-table td.metadata-td{
    font-weight: 700;
    width: auto;
}
.CardMetadata .seats-td{
    display: flex;
    gap: 5px;
}
.CardMetadata .seats{
    width: 18px;
    height: 24px;
    background-color: #dadada;
}