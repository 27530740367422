@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

/* Existing CSS */

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar-chart-container {
  width: 100%;
  font-family: "Roboto Condensed", sans-serif;
  height: 100%;
}

.chart-wrapper {
  min-height: 330px;
}

/* Used for Doughnut Graphs */
.doughnut-chart-container {
  display: flex;
  justify-content: center; /* Adjust as needed */
  align-items: center; /* Adjust as needed */
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
}

.doughnut-chart {
  flex: 1;
  max-width: 33.3%; /* Adjust max-width as needed */
  /* font-family: "Roboto Condensed", sans-serif; */
}

.bar-header {
  display: flex;
  justify-content: space-between;
  background-color: #f6f6f6;
  position: relative;
  align-items: center;
  font-size: 16px;
}
.bar-header p {
  font-family: "Roboto Condensed", sans-serif;
  padding: 7px 5px;
  margin: 0;
}
.bar-header p sup {
  font-size: 8px;
}
.bar-header p:not(:last-of-type):nth-of-type(2) {
  position: absolute;
  right: 80px;
  padding: 0;
}
.bar-header p:last-of-type {
  margin-right: 10px;
}
.bar-chart-container .bar-header p.firstPrefHeader {
  margin-top: -2px;
}

/* Container Queries */
.chart-container {
  container-type: inline-size;
  container-name: chart;
}

/* If the container is larger than 700px */
@container (min-width: 700px) {
  .bar-chart-container {
    /* transform: scaleX(-1); */
    /* background-color: rgb(250 250 250); */
  }
}

.bar-header p {
  font-family: "Roboto Condensed", sans-serif;
  padding: 7px 5px;
  margin: 0;
}
.bar-header p sup {
  font-size: 8px;
}
.bar-header p:not(:last-of-type):nth-of-type(2) {
  position: absolute;
  right: 80px;
  padding: 0;
}
.bar-header p:last-of-type {
  margin-right: 10px;
}
.bar-chart-container .bar-header p.firstPrefHeader {
  margin-top: -2px;
}

.ceann-comharile-text {
  font-family: "Roboto Condensed", sans-serif;
  font-style: italic;
  font-size: 14px;
  text-align: center;
}
