@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

.NationalPicture {
  font-family: Roboto, sans-serif;
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;
}

.NationalPicture .party-number {
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
}

.NationalPicture .party-code{
  text-transform: uppercase;
}

.NationalPicture .label {
  font-size: 18px;
  font-weight: 400;
}

.NationalPicture .party-frame {
  padding: 9px 0;
  border: 1px solid #ddd;
  color: white;
  text-align: center;
  width: 120px;
  min-width: 120px;
}

.NationalPicture .status-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 0 16px 16px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
}

.NationalPicture .parties-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  margin: auto;
}

.NationalPicture .party-frame sup { 
  vertical-align: super;
  font-size: smaller;
}

.NationalPicture .first-pref-text {
  font-size: 14px;
}

.NationalPicture .first-pref-text > span{
  font-weight: 700;
}

.NationalPicture .summary-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 16px 0 0;
}

.NationalPicture .summary-item {
  border: 1px solid #ddd;
  padding: 6px 0;
  flex: 0 1 350px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 1px;
}

.NationalPicture .summary-figure {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.NationalPicture .ceann-comharile-text {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  margin-top: 8px;
}

.NationalPicture .icon {
  width: 16px;
  display: inline-flex;
  align-items: center;
}

.NationalPicture .icon.complete {
  fill: #46b33c;
}

.NationalPicture .text.complete {
  color: #46b33c;
}

.NationalPicture .icon.pending {
  fill: #191919;
}

.NationalPicture .text.pending {
  color: #191919;
}

.NationalPicture .icon.counting {
  fill: #e07000;
}

.NationalPicture .text.counting {
  color: #e07000;
}

@media (max-width: 1200px) {
  .NationalPicture .parties-container {
    margin: 0 auto;
    max-width: 760px;
  }
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {

  .NationalPicture .summary-container {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 16px 0 0;
    flex-direction: column;
  }
  .NationalPicture .summary-item {
    flex: 1 1 auto;
  }

  .NationalPicture .parties-container {
    margin: auto 0;
  }

  .NationalPicture  .grid-item {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .NationalPicture .grid-item {
    width: 100px;
    height: 100px;
  }

  .NationalPicture .number {
    font-size: 36px;
  }

  .NationalPicture .label {
    font-size: 14px;
  }
}
