@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

/* general table styling */
.table {
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.table .title {
  border-top: 2px solid #000;
  margin: 0 0 10px;
  padding: 2px 0;
  text-align: left;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
}

.table .controls {
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0;
}

/* dropdown menu for table sort */
.table .controls .dropdown-menu {
  display: flex;
  margin-right: 10px;
  align-items: center;
}

.table .controls .dropdown-menu label {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #575757;
  margin-right: 10px;
}

.table .controls .dropdown-menu select {
  width: 125px;
  height: 30px;
  padding: 4px;
  border-radius: 4px;
  border: 0.25px solid #9a9a9a;
  font-family: "Roboto", sans-serif;
  line-height: 18px;
}

.table .controls .dropdown-menu select:focus {
  background-color: #f1f1f1;
  box-shadow: 2px 2px 4px 0px #00000040;
}

.table .controls .dropdown-menu select option {
  text-align: end;
}

.table .scroll-prompt {
  align-content: center;
}

.tabulator {
  overflow-x: scroll !important;
  padding-bottom: 3px;
}

/* for browsers that support the '::-webkit-scrollbar' pseudo property */
@supports selector(::-webkit-scrollbar) {
  .tabulator::-webkit-scrollbar {
    height: 12px;
  }

  .tabulator:hover::-webkit-scrollbar-track {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    background: rgb(222, 222, 222);
  }

  .tabulator::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    background: rgb(160, 160, 160);
  }
}

.tabulator .tabulator-frozen-left {
  border-right: 1px solid #000 !important;
  box-shadow: 2px 2px 4px 0px #00000040;
}

.tabulator .tabulator-header {
  font-family: "Roboto Condensed", sans-serif;
  overflow: visible !important;
  border-bottom: none;
}

.tabulator .tabulator-header .tabulator-header-contents {
  width: max-content;
}

.tabulator .tabulator-header .tabulator-col {
  background-color: #f1f1f1;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  padding-right: 4px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-title {
  font-size: 13px;
  font-weight: 400;
  padding-right: 20px !important;
}

.tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-sorter
  .tabulator-arrow {
  border-bottom: 6px solid #575757 !important;
}

.tabulator .tabulator-tableholder {
  min-height: 1px;
  width: 100%;
  width: -moz-available; /* Firefox */
  width: -webkit-fill-available; /* Safari and Chrome */
  width: stretch;
}

.tabulator .tabulator-tableholder .tabulator-table {
  width: max-content;
}

.tabulator .tabulator-tableholder .tabulator-row {
  border-bottom: 1px solid rgba(218, 218, 218, 1);
}

/* candidate column layout */
.tabulator .tabulator-table .candidates {
  padding: 2.5px 5px;
  flex-direction: row-reverse;
  justify-content: space-between !important;
}

@media screen and (max-width: 365px) {
  .tabulator .tabulator-header .candidates,
  .tabulator .tabulator-table .candidates {
    max-width: 200px;
  }
}

.tabulator .tabulator-table .candidates .party {
  font-family: "Roboto Condensed", sans-serif;
  color: #fff;
  font-weight: 700;
  display: grid;
  height: 24px;
  width: 40px;
  margin-right: 5px;
  align-content: center;
  text-align: center;
  text-transform: uppercase;
}

.tabulator .tabulator-table .candidates .candidate {
  display: inline-flex;
  height: 40px;
  width: 80%;
  justify-content: space-between;
}

.tabulator .tabulator-table .candidates .candidate .name {
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  max-width: 150px;
  display: grid;
  align-content: center;
  text-wrap: balance;
}

.tabulator .tabulator-table .candidates .candidate .status {
  display: grid;
  align-content: center;
  width: 20px;
}

.tabulator .tabulator-table .count-elected {
  font-weight: 600;
  text-decoration-skip-ink: none;
  text-decoration: #73cd7e underline solid;
  -webkit-text-decoration: #73cd7e underline solid;
  text-decoration-thickness: 2px;
}
