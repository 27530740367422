@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Roboto+Condensed:wght@400;700&display=swap');

.NationalCard{
    display: flex;
    flex-wrap: wrap;
    font-family: "Roboto", sans-serif;
    justify-content: center;
}
.NationalCard-list{
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    gap: 16px;
}
.NationalCard > div{
    min-width: 320px;
    max-width: 400px; 
    flex: 1 1 0;
    box-sizing: border-box;
}
.NationalCard .arrow,
.NationalCard-container .arrow{
    width: 22px;
    transition: transform 0.3s ease;
    cursor: pointer;
    padding:0;
    background: none;        
    border: none;                  
    display: inline-block;   
}
.NationalCard .arrow.rotated,
.NationalCard-container .arrow.rotated {
    transform: rotate(180deg);
    margin-top: -4px;
    cursor: pointer;
    background: none;        
    border: none;                       
    display: inline-block;   
}
.NationalCard .content-card.hide {
    max-height: 0;
    transition:max-height 0.3s ease;
}
.NationalCard .content-card {
    overflow:hidden;
    transition:max-height 0.3s ease-out;
    max-height:400px;
}
.NationalCard h2 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    margin: 5px 0;
}
.NationalCard .header-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid #000;
}
.NationalCard .seats {
    width: 48px;
    height: 26px;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    color: #FFF;
    background-color: #dadada;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
}
.NationalCard .seats-small {
    width: 40px;
    height: 24px;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    color: #FFF;
    background-color: #dadada;
    line-height: 26px;
    text-align: center;
    text-transform: uppercase;
}
.NationalCard .container-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 9px;
    padding: 6px 0;
}
.NationalCard .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 36px;
}
.NationalCard .icon{
    width: 14px;
    display: inline-flex;
    vertical-align: middle;
    margin-top: -3px;
}
.NationalCard .text{
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    line-height: 36px;
    margin-left: 5px;
}
.NationalCard .icon.complete {
    fill: #46B33C;
}
.NationalCard .text.complete {
    color: #46B33C;
}
.NationalCard .icon.pending {
    fill: #191919;
}
.NationalCard .text.pending {
    color: #191919;
}
.NationalCard .icon.counting {
    fill: #E07000;
}
.NationalCard .text.counting {
    color: #E07000;
} 
.NationalCard .count-elected {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    color: #191919;
    text-align: right;
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    line-height: 32px;
    background-color: #f1f1f1;
}
.NationalCard .count-elected > div {
    margin: 0 13px;
    font-family: "Roboto", sans-serif;
}
.NationalCard .count-elected > div:last-of-type{
    margin-right: 10px;
}
.NationalCard .full-data {
    width: 100%;
    border-collapse: collapse;
}
.NationalCard .full-data tr {
    height: 48px;
    border-bottom: 1px solid #D6D6D6;
}
.NationalCard .full-data td {
    font-size: 16px;
    text-align: left;
    vertical-align: middle;
}
.NationalCard td.first-pref {
    font-weight: 700;
    text-align: right;
    width: 80px;
}
.NationalCard td.count-e {
    text-align: right;
    padding-right: 10px;
    width: 20px;
}
.NationalCard .party-box {
    text-align: left;
    padding-left: 0;
    padding-right: 5px;
    width: 50px;
}

.NationalCard-container .collapse {
    position: absolute;
    background-color: white;
    right: 0;
    line-height: 36px;
    border: 0;
    vertical-align: middle;
    color:#000000;
}
.NationalCard-container .collapse SVG.arrow {
    height: 20px;
    vertical-align: middle;
}
.NationalCard-container .region-filter-group{
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-top: 0px;
    gap: 10px 20px;
    flex-wrap: wrap;
    position: relative;
}
.NationalCard-container .region-filter-btn{
    border: 1px solid #999;
    padding: 0.5em 1.5em;
    border-radius: 0.25em;
    color: #555;
    cursor: pointer;
    font-weight: 600;
    line-height: 1.2em;
    transition: all 0.2s ease-in-out;
}
.NationalCard-container .region-filter-btn:hover{
    background-color: #888;
    color: #fff;
}
.NationalCard-container .region-filter-btn[aria-pressed="true"]{
    background-color: #2b2b2b;
    color: #fefefe;
}
.NationalCard-list.NationalCard .full-data tr:last-child {
    border: none;
}
.NationalCard-list .NationalCard h2 {
    margin-top:0;
    }
.count-elected sup {
    font-size: smaller;
    vertical-align: super;
    line-height: 0;
}

.NationalCard-list.NationalCard .header-card {
    border-top: none;
}
    
.NationalCard-container .view-full-btn{
    margin-top: 0;
    width: 100%;
    display: inline-block;
    padding: 0.75em 2em;
    border-radius: 0.25em;
    box-sizing: border-box;
    font-size: 1em;
    border: 1px solid #999;
    line-height: 1em;
    font-weight: 800;
    text-transform: uppercase;
    text-decoration: none;
    color: #656565;
    transition: all 0.2s ease-in-out 0s;
    text-align: center;
}

.NationalCard-container .view-full-btn.no-link{
    pointer-events: none;
    opacity: 0.8;
    background-color: #ddd;
    color: #888;
}

.NationalCard-container .view-full-btn:hover{
    background-color: #888;
    color: #fff;
}
.NationalCard-list > div {
    padding: 8px 16px 16px 16px;
    border: 1px solid #DDD;
    height: fit-content;
    position: relative;
}
@media (max-width: 1024px) {
    .NationalCard-container .collapse {
    position: relative;
    display: block;
    text-align: right;
    margin:-10px 0 0 10px;
    width: 100%;
    }
    .NationalCard-container .region-filter-group {
        padding-bottom: 0;
    }
}
@media (max-width: 767px) {
    .NationalCard-container .region-filter-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    
        /* Target the last item */
        > *:last-child {
            grid-column: 1 / -1; /* Span from the first column to the last */
        }
    }
}
